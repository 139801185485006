import React from "react"

const ContactCard = (props) => (
  <div className="contact-card">
    <h3 className="contact-card-title">{props.title}</h3>
    {props.children}
  </div>
)

export default ContactCard;
