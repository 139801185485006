import React from "react"
import { Helmet } from "react-helmet"
// import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import ContactCard from "../components/contactCard"
import MailLink from "../components/mailLink"
import CallBackForm from "../components/callBackForm"

const ContactPage = props => (
  <Layout location={props.location}>
    <Helmet>
      <title>Contact Us</title>
      <meta
        name="description"
        content="Contact C.D. Carter & Co for all aspects of professional roofing services across Surrey & the South of England."
      />
    </Helmet>

    <section className="hero">
      <div className="container">
        <PageTitle
          title="Contact Us"
          subtitle="General enquiries, estimates, repairs or a new project, we’d love to hear from you"
        />

        <div className="hero-contact">
          <ContactCard title="General enquiries">
            <p className="medium">
              <MailLink href="mailto:hello@cdcarter.co.uk?Subject=General Enquiry">
                hello@cdcarter.co.uk
              </MailLink>
              <br />
              01483 890707
            </p>
            <a href="tel:00441483890707" className="btn">
              Call now
            </a>
          </ContactCard>

          <ContactCard title="New business">
            <p className="medium">
              <MailLink href="mailto:craig@cdcarter.co.uk?Subject=New Business Enquiry">
                craig@cdcarter.co.uk
              </MailLink>
              <br />
              07932 031630
            </p>
            <a href="tel:00447932031630" className="btn">
              Call now
            </a>
          </ContactCard>

          <ContactCard title="Careers">
            <p>We are always looking for new talent. Time to apply!</p>
            <p className="medium">
              <MailLink href="mailto:careers@cdcarter.co.uk?Subject=Careers Enquiry">
                careers@cdcarter.co.uk
              </MailLink>
            </p>
          </ContactCard>
        </div>
      </div>
    </section>

    <section className="getintouch">
      <div className="getintouch-inner container">
        <h2>Prefer a callback?</h2>
        <p className="getintouch-para">
          Simply fill in this form giving us an outline of what you are looking
          for and we will be in touch within 24 hours.
        </p>
        <CallBackForm />
      </div>
    </section>
  </Layout>
)

export default ContactPage
