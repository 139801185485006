import React, { Component } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as emailjs from 'emailjs-com';
import Recaptcha from "react-recaptcha";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'We need a longer name, are those initials?')
    .max(50, 'Woah huge name, can you shorten it?')
    .required('You forgot to add your name'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Please enter a valid phone number')
    .required('You forgot to add your number'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  projectType: Yup.string()
    .min(2, 'Too little detail')
    .max(150, 'Too much detail! Please keep it under 150 characters')
    .required('Please enter a project type'),
  projectInfo: Yup.string()
    .max(500, 'Too much info! Please keep it under 500 characters'),
  recaptcha: Yup.string()
    .required('Are you a robot?')
});


class CallBackForm extends Component {

  constructor () {
    super()
    this.state = {
      isHidden: true
    }
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleSubmit = async(values, { setSubmitting }) => {
    await emailjs.sendForm('cdcarterco','template_zyPQJoVq_clone', '#callBackForm', 'user_847jAn75t1OQF2Bg65cvM')
    	.then((response) => {
    	  // console.log('SUCCESS!', response.status, response.text);
        setSubmitting(false);
        this.setState({
          isHidden: false
        })
    	}, (err) => {
    	   console.log('FAILED...', err);
    	});
  }

  render() {
    return (
      <div className="form-container">
        {this.state.isHidden && (
          <Formik
            initialValues={{
              name: '',
              phoneNumber: '',
              email: '',
              projectType: '',
              projectInfo: '',
              recaptcha: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue }) => (
              <Form id="callBackForm">
                <div className="form-field">
                  <label>Name
                    <Field name="name" />
                  </label>
                  {errors.name && touched.name ? (
                    <div className="form-error small">{errors.name}</div>
                  ) : null}
                </div>

                <div className="form-field">
                  <label>Phone number
                    <Field name="phoneNumber" type="tel" />
                  </label>
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <div className="form-error small">{errors.phoneNumber}</div>
                  ) : null}
                </div>

                <div className="form-field">
                  <label>Email address
                    <Field name="email" type="email" />
                  </label>
                  {errors.email && touched.email ? <div className="form-error small">{errors.email}</div> : null}
                </div>

                <div className="form-field">
                  <label>What type of project is it?
                    <Field name="projectType" placeholder="Eg. New roof, repairs etc..." />
                  </label>
                  {errors.projectType && touched.projectType ? (
                    <div className="form-error small">{errors.projectType}</div>
                  ) : null}
                </div>

                <div className="form-field">
                  <label>Tell us about your project
                    <Field name="projectInfo" component="textarea" placeholder="Give us a brief description if you can..." />
                  </label>
                  {errors.projectInfo && touched.projectInfo ? (
                    <div className="form-error small">{errors.projectInfo}</div>
                  ) : null}
                </div>

                <div className="form-field">
                  <Recaptcha
                    sitekey="6LdTP7gUAAAAAIUgd-Qw65iGCQE5UIrDLbHoCtiH"
                    render="explicit"
                    verifyCallback={(response) => { setFieldValue("recaptcha", response); console.log(response); }}
                    onloadCallback={() => { console.log("reCaptcha loaded"); }}
                  />
                  {errors.recaptcha && touched.recaptcha ? (
                    <div className="form-error small">{errors.recaptcha}</div>
                  ) : null}
                </div>

                <button type="submit" disabled={isSubmitting} className="btn">
                  {isSubmitting ? 'Sending...' : 'Call me back'}
                </button>
              </Form>
            )}
          </Formik>
        )}

        {!this.state.isHidden && (
          <div className="form-success">
            <h3>Nice, your callback request has been received...</h3>
            <p>Now sit back, relax and we'll be in touch :)</p>
          </div>
        )}
      </div>

    );
  }
}

export default CallBackForm;
